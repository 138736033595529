import { Route } from '@angular/router';
import { Layout } from './components/layout/layout';
import { Login } from './pages/onboarding/login/login';
import { Home } from './pages/dashboard/home/home';
import { authGuard } from './guards/auth.guard';
import { loginGuard } from './guards/login.guard';
import { Recovery } from './pages/onboarding/recovery/recovery';
import { NewPassword } from './pages/onboarding/new-password/new-password';
import { UserType } from '@models/user';

export const routes: Route[] = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: Login,
    canActivate: [loginGuard()],
  },
  {
    path: 'recovery',
    component: Recovery,
    canActivate: [loginGuard()],
  },
  {
    path: 'new-password',
    component: NewPassword,
    canActivate: [loginGuard()],
  },
  {
    path: 'dashboard',
    component: Layout,
    children: [
      {
        path: '',
        component: Home,
        canActivate: [authGuard([UserType.ADMIN, UserType.REFEREE_COORDINATOR], true, true)],
      },
      {
        path: 'users',
        canActivate: [authGuard([UserType.ADMIN])],
        loadChildren: () => import('./pages/dashboard/users/users.routes'),
      },
      {
        path: 'clubs',
        canActivate: [authGuard([UserType.ADMIN])],
        loadChildren: () => import('./pages/dashboard/clubs/clubs.routes'),
      },
      {
        path: 'teams',
        canActivate: [authGuard([UserType.ADMIN])],
        loadChildren: () => import('./pages/dashboard/teams/teams.routes'),
      },
      {
        path: 'club',
        canActivate: [authGuard([], true)],
        loadChildren: () => import('./pages/club-manager/club-manager.routes'),
      },
      {
        path: 'referees',
        canActivate: [authGuard([UserType.REFEREE_COORDINATOR])],
        loadChildren: () => import('./pages/referees-coordinator/referees-coordinator.routes'),
      },
      {
        path: 'competitions',
        canActivate: [authGuard([UserType.ADMIN])],
        loadChildren: () => import('./pages/dashboard/competition/competition.routes'),
      },
      {
        path: 'calendar',
        canActivate: [authGuard([UserType.ADMIN])],
        loadChildren: () => import('./pages/dashboard/calendar/calendar.routes'),
      },
      {
        path: 'acta',
        canActivate: [authGuard([UserType.ADMIN])],
        loadChildren: () => import('./pages/dashboard/acta/acta.routes'),
      },
      {
        path: 'movements',
        canActivate: [authGuard([UserType.ADMIN])],
        loadChildren: () => import('./pages/dashboard/movements/movements.routes'),
      },
      {
        path: 'change-dates',
        canActivate: [authGuard([UserType.ADMIN])],
        loadChildren: () => import('./pages/dashboard/change-dates/change-dates.routes'),
      },
      {
        path: 'incidents',
        canActivate: [authGuard([UserType.ADMIN])],
        loadChildren: () => import('./pages/dashboard/incidents/incidents.routes'),
      },
      {
        path: 'transfers',
        canActivate: [authGuard([UserType.ADMIN])],
        loadChildren: () => import('./pages/dashboard/transfers/transfers.routes'),
      },
      {
        path: 'license-rates',
        canActivate: [authGuard([UserType.ADMIN])],
        loadChildren: () => import('./pages/dashboard/license-rates/license-rates.routes'),
      },
      {
        path: 'emails',
        canActivate: [authGuard([UserType.ADMIN])],
        loadChildren: () => import('./pages/dashboard/emails/emails.routes'),
      },
      {
        path: 'notifications',
        canActivate: [authGuard([UserType.ADMIN])],
        loadChildren: () => import('./pages/dashboard/notifications/notifications.routes'),
      },
      {
        path: 'reports',
        canActivate: [authGuard([UserType.ADMIN])],
        loadChildren: () => import('./pages/dashboard/reports/reports.routes'),
      },
      {
        path: 'inscriptions',
        canActivate: [authGuard([], true)],
        loadChildren: () => import('./pages/inscriptions/inscriptions.routes'),
      },
    ],
  },
];
