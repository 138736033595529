import { Component, isDevMode } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { prime } from './primeng.i18n';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'admin-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule],
  template: `<router-outlet></router-outlet>
    <div class="dev" *ngIf="isDev">DEV BUILD</div>`,
  styles: [
    `
      .dev {
        background-color: red;
        color: white;
        font-size: 12px;
        position: fixed;
        padding: 2px;
        right: -8vw;
        text-align: center;
        transform: rotate(45deg);
        width: 19vw;
        top: 1vw;
        pointer-events: none;
        z-index: 2;
      }
    `,
  ],
})
export class AppComponent {
  public isDev = isDevMode();
  constructor(private config: PrimeNGConfig) {
    this.config.setTranslation(prime);
  }
}
